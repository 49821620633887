import 'core-js/actual';

import '@primer/css/dist/markdown.css';
import { Suspense } from 'react';
import './index.css';

import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import { createRoot } from 'react-dom/client';
import { crispInject } from './crispInject';
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';
import { useUpdateGlobalUserAgent } from './utils/userAgent';
import { Entry } from './Entry';
import { isDevelopment } from './config/local';

dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.locale('zh-cn');

const container = null;

import * as React from 'react';
import { useState } from 'react';
import { screen, fireEvent } from '@testing-library/dom';

// https://stackoverflow.com/questions/71792005/react-18-you-are-calling-reactdomclient-createroot-on-a-container-that-has-a
document.addEventListener('DOMContentLoaded', (event) => {
	if (!container) {
		const container = document.getElementById('root');
		if (container) {
			const root = createRoot(container);

			root.render(<Entry />);
		}
	}

	// crispInject();
});

if (import.meta.hot) {
	import.meta.hot.accept();
}

if (!isDevelopment) {
	const n: HTMLScriptElement = document.createElement('script');
	n.crossOrigin = 'anonymous';
	n.src = 'https://js.sentry-cdn.com/7ebfc2996e7b4b0db1f99be99fc69d5c.min.js';

	document.head.appendChild(n);
}
